<template>
  <div>
    <div class="fd-img-wrap">
      <img style="width:100%" src="../assets/images/cp-banner.png" alt="">
    </div>
    <div class="fd-cp-content-wrap">
      <div class="fd-cp-left-wrap">
        <div class="fd-cp-left-item" :class="{'fd-checked': keyIndex === 0}" @click="adcpClick(0)">数据及咨询服务</div>
        <div class="fd-cp-left-item" :class="{'fd-checked': keyIndex === 1}" @click="adcpClick(1)">智慧金融产品</div>
        <div class="fd-cp-left-item1"></div>
      </div>
      <div class="fd-cp-right-wrap">
        <!-- 数据及咨询服务板块 -->
        <div class="fd-cp-sj-wrap" v-show="keyIndex === 1">
          <div class="fd-cp-sj-wrap-item" :class="{'fd-back-img': setIndex === 0}" @mouseenter="positionClick(0)" @mouseleave="cancleIndex">
            <div style="padding-top: 28px;padding-left: 20px;padding-right: 16px">
              <div class="fd-title-wrap" :class="{'fd-color': setIndex === 0}">
                <span>数据巡航</span>
                <img v-if="setIndex !== 0" src="../assets/images/shuju.png" alt="">
              </div>
              <div class="fd-content-text-wrap" :class="{'fd-color': setIndex === 0}">
                具有“实时市场监测、专业模型构建、深度数据透视、精确历史回溯” 等优势，主要包含公告解析、全景监测、灵动解盘、龙虎大师、相似K线、筹码分布、牛散追踪等模块。
              </div>
             </div>
          </div>
          <div class="fd-cp-sj-wrap-item" :class="{'fd-back-img1': setIndex === 1}" @mouseenter="positionClick(1)" @mouseleave="cancleIndex">
            <div style="padding-top: 28px;padding-left: 20px;padding-right: 16px">
              <div class="fd-title-wrap" :class="{'fd-color': setIndex === 1}">
              <span>交易分析</span>
              <img v-if="setIndex !== 1" src="../assets/images/zonghefenxi.png" alt="">
            </div>
            <div class="fd-content-text-wrap" :class="{'fd-color': setIndex === 1}">
              包含交易时间、冲击成本、业绩归因分析等模块，主要为证券公司、基金公司的自营投资经理、基金经理，特别是量化投资的基金经理，提供有力的分析工具。
            </div>
            </div>
          </div>
          <div class="fd-cp-sj-wrap-item" :class="{'fd-back-img2': setIndex === 2}" @mouseenter="positionClick(2)" @mouseleave="cancleIndex">
            <div style="padding-top: 28px;padding-left: 20px;padding-right: 16px">
              <div class="fd-title-wrap" :class="{'fd-color': setIndex === 2}">
                <span>客户营销优化</span>
                <img v-if="setIndex !== 2" src="../assets/images/zhinengyouhua.png" alt="">
              </div>
              <div class="fd-content-text-wrap" :class="{'fd-color': setIndex === 2}">
                主要为证券公司经纪业务部门开展客户服务工作提供精准营销的解决方案，降低成本的同时提高收入。
              </div>
            </div>
          </div>
          <div class="fd-cp-sj-wrap-item" :class="{'fd-back-img3': setIndex === 3}" @mouseenter="positionClick(3)" @mouseleave="cancleIndex">
            <div style="padding-top: 28px;padding-left: 20px;padding-right: 16px">
              <div class="fd-title-wrap" :class="{'fd-color': setIndex === 3}">
                <span>客户流失预警</span>
                <img v-if="setIndex !== 3" src="../assets/images/yujing.png" alt="">
              </div>
              <div class="fd-content-text-wrap" :class="{'fd-color': setIndex === 3}">
                精准预警可能即将流失的客户及其流失原因，为挽留决策和营销方案提供数据支持，发信客户流失的共性，优化日常服务，提高客户体验。
              </div>
            </div>
          </div>
        </div>
        <!-- 智慧金融产品板块 -->
        <div class="fd-cp-zh-wrap" v-show="keyIndex === 0">
          <div class="fd-cp-zh-item" style="margin-bottom:30px">
            <div class="fd-cp-zh-item-left">
              <img src="../assets/images/fd-zh-001.png" alt="">
            </div>
            <div class="fd-cp-zh-item-right">
              <h2 style="margin-bottom:20px;font-size:24px; color: #026BA7">沪伦通</h2>
              <div style="display:flex;align-items:center;height:30px;margin-bottom:15px">
                <!-- 表示彩色 -->
                <span style="width:3px;height:16px;background:rgba(236,105,65,1);display:inline-block;margin-right:5px"></span>
                <span style="font-size:18px;color:#333;margin-right:20px">目标</span>
                <span style="font-size:18px;color:#333;line-height:30px">沪伦通数据及资讯服务先锋者。</span>
              </div>
              <div style="display:flex;align-items:baseline">
                <span style="width:3px;height:16px;background:rgba(0,183,238,1);display:inline-block;margin-right:5px"></span>
                <span style="font-size:18px;color:#333;margin-right:20px">
                  优势
                </span>
                <span style="font-size:18px;color:#333;line-height:30px">
                  1. 首批提供沪伦通数据资讯的数据服务商;<br>
                  2. 根据中国大陆投资者的使用习惯， 优化产品体验;<br>
                  3. 不断深入研究沪伦通市场，将其与投资者教育相结合。
                </span>
              </div>
            </div>
          </div>
          <div class="fd-cp-zh-item" style="margin-bottom:30px">
            <div class="fd-cp-zh-item-left">
              <img src="../assets/images/fd-zh-002.png" alt="">
            </div>
            <div class="fd-cp-zh-item-right">
              <h2 style="margin-bottom:20px;font-size:24px; color: #026BA7">港股通</h2>
              <div style="display:flex;align-items:center;height:30px;margin-bottom:15px">
                <!-- 表示彩色 -->
                <span style="width:3px;height:16px;background:rgba(236,105,65,1);display:inline-block;margin-right:5px"></span>
                <span style="font-size:18px;color:#333;margin-right:20px;line-height:30px">目标</span>
                <span style="font-size:18px;color:#333;line-height:30px">一站式港股数据及资讯服务。</span>
              </div>
              <div style="display:flex;align-items:baseline">
                <span style="width:3px;height:16px;background:rgba(0,183,238,1);display:inline-block;margin-right:5px"></span>
                <span style="font-size:18px;color:#333;margin-right:20px">
                  优势
                </span>
                <span style="font-size:18px;color:#333;line-height:30px">
                  1. 目前国内唯一一家具有港交所公告接收授权的金融科技服务商;<br>
                  2. 市场新闻来源于香港权威媒体;<br>
                  3. 其他数据源于傲度信息自行采集或第三方授权提供。
                </span>
              </div>
            </div>
          </div>
          <div class="fd-cp-zh-item">
            <div class="fd-cp-zh-item-left">
              <img src="../assets/images/fd-zh-003.png" alt="">
            </div>
            <div class="fd-cp-zh-item-right">
              <h2 style="margin-bottom:20px;font-size:24px; color: #026BA7">A股F10（PC版 & 手机版）</h2>
              <div style="font-size:18px;line-height:30px;color:#333">指标覆盖面广，数据多元化。内容涵盖公司概况、发行上市、股本股东、公司高管、分红融资、交易数据、财务数据及分析、盈利预测等。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { setInterval, clearInterval } from 'timers';
export default {
  name: 'cpxl',
  data () {
    return {
      keyIndex: 0,
      setIndex: -1,
      timer: null
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    positionClick (index) {
      this.setIndex = -1
    },
    cancleIndex () {
      this.setIndex = -1
    },
    adcpClick (index) {
      this.keyIndex = index
      this.setIndex = -1
      clearInterval(this.timer)
      if (index === 0) {
        // this.changeSetIndx()
      }
    },
    // 循环数据
    changeSetIndx () {
      this.setIndex = 0
      this.timer = setInterval(() => {
        this.setIndex +=1
        if (this.setIndex === 4) {
          this.setIndex = 0
        }
      }, 3000)
    }
  },
  mounted () {
    if (this.keyIndex === 0) {
      // 说明是数据及咨询服务板块
      // this.setIndex = 0
      // this.changeSetIndx()
    }
  }
}
</script>
<style lang="stylus" scoped>
.fd-cp-zh-item-right
  width 671px
  padding-top 34px
  padding-left 30px
.fd-checked
  background-color rgba(2,107,167,1) !important
  color white !important
.fd-cp-content-wrap
  width 1200px
  margin 0 auto 
  padding 80px 0
  display flex
  justify-content space-between
  // align-items center
  .fd-cp-left-wrap
    width 175px
    .fd-cp-left-item
      width 175px
      height 50px
      color #026BA7
      font-size 20px
      line-height 50px
      text-align center
      cursor pointer
      background-color rgba(246,246,246,1)
      margin-bottom 10px
    .fd-cp-left-item1
      width 175px
      height calc(100% - 110px)
      background-color rgba(246,246,246,1)
  .fd-cp-right-wrap
    width 995px
    // height 858px
    display flex
    flex-wrap wrap
    align-content space-between
    .fd-cp-zh-wrap
      .fd-cp-zh-item
        height 266px
        display flex
        background rgba(255,255,255,1)
        box-shadow 0px 0px 13px 0px rgba(130,130,130,0.24)
        .fd-cp-zh-item-left
          width 378px
          height 266px
          img
            width 100%
            height 100%
            vertical-align middle
    .fd-cp-sj-wrap
      display flex
      height 501px
      flex-wrap wrap
      justify-content space-between
      align-content space-between
      .fd-cp-sj-wrap-item
        cursor pointer
        width 480px
        height 237px
        background-color rgba(255,255,255,1)
        box-shadow 0px 0px 13px 0px rgba(130,130,130,0.24)
        .fd-title-wrap
          display flex
          height 42px
          justify-content space-between
          align-items center
          color #222
          font-size 24px
          margin-bottom 34px
          font-family 'SourceHanSansCN-Medium'
          font-weight 500
        .fd-content-text-wrap
          color #666666
          font-size 18px
          line-height 30px
.fd-color
  color white !important
.fd-back-img
  background url(../assets/images/fd-001.png) center no-repeat !important
.fd-back-img1
  background url(../assets/images/fd-002.png) center no-repeat !important
.fd-back-img2
  background url(../assets/images/fd-003.png) center no-repeat !important
.fd-back-img3
  background url(../assets/images/fd-004.png) center no-repeat !important
.fd-cp-left-item:hover
  color #76c3ff !important
  text-decoration underline
</style>
